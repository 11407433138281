import { useContext } from "react";
import { AppContext } from "../../AppContext";
import Button from "@mui/material/Button";

function Logout() {
  const { setGlobalState } = useContext(AppContext);

  const handleLogout = () => {
    // Clear stored data in localStorage
    localStorage.removeItem("userId");
    localStorage.removeItem("isLoggedIn");
    setGlobalState((prevState) => ({
      ...prevState,
      userId: null,
      isLoggedIn: false,
    }));
    window.location.href = "/";
  };
  return (
    <div>
      <Button onClick={handleLogout} variant="contained" color="secondary" size="large">
        Logout
      </Button>
    </div>
  );
}

export default Logout;
