import React from "react";

export interface GlobalState {
  darkMode: boolean;
  userId: number | null;
  isLoggedIn: boolean;
}

const storedUserId = localStorage.getItem("userId");
const storedIsLoggedIn = localStorage.getItem("isLoggedIn");

interface AppContextProps {
  globalState: GlobalState;
  setGlobalState: React.Dispatch<React.SetStateAction<GlobalState>>;
}

export const AppContext = React.createContext<AppContextProps>({
  globalState: {
    darkMode: true,
    userId: storedUserId !== null ? Number(storedUserId) : null,
    isLoggedIn: storedIsLoggedIn !== null ? JSON.parse(storedIsLoggedIn) : false,
  },
  setGlobalState: () => {},
});