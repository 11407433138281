import Button from "../components/Button";
import Typography from "../components/Typography";
import ProductHeroLayout from "./ProductHeroLayout";
import coffeeImg from "../../banner.jpg";

export default function ProductHero() {
  return (
    <ProductHeroLayout
      sxBackground={{
        backgroundImage: `url(${coffeeImg})`,
        backgroundColor: "#7fc7d9", // Average color of the background image.
        backgroundPosition: "center",
      }}
    >
      <img
        style={{ display: "none" }}
        src={coffeeImg}
        alt="increase priority"
      />
      <Typography
        sx={{ fontFamily: "'Kalam', cursive;" }}
        color="inherit"
        align="center"
        variant="h2"
        marked="center"
      >
        Fashion App
      </Typography>
      <Typography
        color="inherit"
        align="center"
        variant="h5"
        sx={{ mb: 4, mt: { xs: 4, sm: 10 } }}
      >
        Get fashion updates and suggestions from the community!
      </Typography>
      <Button
        color="secondary"
        variant="contained"
        size="large"
        component="a"
        href="/feed"
        sx={{ minWidth: 200 }}
      >
        Start Now
      </Button>
    </ProductHeroLayout>
  );
}
