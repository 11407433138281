import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import { FeedItems } from "../global/data";

interface CommentsProps {
  id: number;
}

export default function Comments({ id }: CommentsProps) {
  return (
    <List sx={{ width: "100%", bgcolor: "background.paper" }}>
      {FeedItems[id].comLs.map((item, index) => (
        <div>
          {" "}
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar
                alt={item.userName}
                src="https://mui.com/static/images/avatar/2.jpg"
              />
            </ListItemAvatar>
            <ListItemText
              primary={item.title}
              secondary={<React.Fragment>{item.content}</React.Fragment>}
            />
          </ListItem>
          {index + 1 !== FeedItems[id].comLs.length && (
            <Divider variant="inset" component="li" />
          )}
        </div>
      ))}
    </List>
  );
}
