import React from "react";
import "./App.css";
import { AppContext, GlobalState } from "./AppContext";
import { RouterProvider } from "react-router-dom";
import { createBrowserRouter } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import CssBaseline from "@mui/material/CssBaseline";
import Home from "./components/common/Home";
import Error from "./components/common/Error";
import TestServer from "./components/TestServer";
import Login from "./components/account/Login";
import SignUp from "./components/account/SignUp";
import Settings from "./components/account/Settings";
import Feed from "./components/Feed";
import LoginTest from "./components/account/LoginTest";
import Logout from "./components/account/Logout";
import SideBar from "./components/common/SideBar";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/test",
    element: <TestServer />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/logintest",
    element: <LoginTest />,
  },
  {
    path: "/signup",
    element: <SignUp />,
  },
  {
    path: "/settings",
    element: <Settings />,
  },
  {
    path: "/logout",
    element: <SideBar content={<Logout />} />,
  },
  {
    path: "*",
    element: <Error errorCode={404} />,
  },
]);

export default function App() {
  const storedUserId = localStorage.getItem("userId");
  const storedIsLoggedIn = localStorage.getItem("isLoggedIn");

  const [globalState, setGlobalState] = React.useState<GlobalState>({
    darkMode: true,
    userId: storedUserId !== null ? Number(storedUserId) : null,
    isLoggedIn:
      storedIsLoggedIn !== null ? JSON.parse(storedIsLoggedIn) : false,
  });

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalState.darkMode ? "dark" : "light",
          primary: {
            light: "#69696a",
            main: "#28282a",
            dark: "#1e1e1f",
          },
          secondary: {
            light: "#fff5f8",
            main: "#ff3366",
            dark: "#e62958",
          },
        },
        components: {
          MuiButton: {
            styleOverrides: {
              root: {
                fontFamily: "'Indie Flower', cursive",
                fontSize: "1.2rem",
                fontWeight: "bold",
              },
            },
          },
        },
        typography: {
          fontFamily: "'Merienda', cursive",
        },
      }),
    [globalState.darkMode]
  );

  return (
    <AppContext.Provider value={{ globalState, setGlobalState }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <RouterProvider router={router} />
      </ThemeProvider>
    </AppContext.Provider>
  );
}
