import React, { useEffect } from "react";
import { apiHost } from "./global/definitions";

const TestServer: React.FC = () => {
  useEffect(() => {
    const sendDataToServer = async () => {
      try {
        const response = await fetch(`${apiHost}users`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: "John Doe",
            email: "johndoe@example.com",
          }),
        });

        if (response.ok) {
          console.log("Data sent successfully to the server");
        } else {
          console.error("Error sending data to the server");
        }
      } catch (error) {
        console.error("Error sending data to the server", error);
      }
    };

    sendDataToServer();
  }, []);

  return <div>Testing Server...</div>;
};

export default TestServer;
