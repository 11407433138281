import React from "react";

export const fName = "Adolf";
export const lName = "Hitler";
export const email = "hitler@nazi.org";
export const password = "hitler'sStrongPassword";
export const initials = fName.substring(0, 1) + lName.substring(0, 1);
export const pfpUrl = null;
export const location = "one-north";
export const ownerId = 0;

export const rdr: (path: string) => void = (path) => {
  window.location.href = path;
};

export function getCurrentDate() {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0");
  const day = String(now.getDate()).padStart(2, "0");
  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
}

export function isEmail(email: string): boolean {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

export function isMobileDevice() {
  const userAgent = navigator.userAgent.toLowerCase();
  const mobileKeywords = [
    "mobile",
    "android",
    "iphone",
    "ipod",
    "ipad",
    "windows phone",
  ];
  const screenWidth = window.screen.width;
  const screenHeight = window.screen.height;
  const isScreenSmall = Math.min(screenWidth, screenHeight) < 768;

  for (let i = 0; i < mobileKeywords.length; i++) {
    if (userAgent.includes(mobileKeywords[i])) {
      return true;
    }
  }

  if (isScreenSmall) {
    return true;
  }

  return false;
}

export function isOnlySpaces(str: string) {
  return str.trim().length === 0;
}

export type CommentsListProps = {
  userName: string;
  title: string;
  content: React.ReactNode | string;
};

export type FeedProps = {
  userName: string;
  title: string;
  date: string;
  media: string;
  description: string;
  extendedDescription?: React.ReactNode;
  isLiked?: boolean;
  comLs: CommentsListProps[];
};

export const FeedItems: FeedProps[] = [
  {
    userName: "Someone",
    title: "My Awesome Post",
    date: "2023-05-05",
    media: "https://mui.com/static/images/buttons/burgers.jpg",
    description: "Description",
    extendedDescription: "ED",
    isLiked: true,
    comLs: [
      { userName: "test", title: "test", content: <p>GELLO</p> },
      { userName: "test", title: "test", content: <p>GELLO</p> },
    ],
  },
  {
    userName: "hello",
    title: "title",
    date: "2023-05-05",
    media: "https://mui.com/static/images/buttons/burgers.jpg",
    description: "Description",
    extendedDescription: "Extended Description",
    comLs: [{ userName: "test", title: "test", content: <p>GELLO</p> }],
  },
];
