import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  ReactNode,
  useState,
} from "react";
import NavBar from "../common/NavBar";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import { CheckCircle, Visibility, VisibilityOff } from "@mui/icons-material";
import dayjs, { Dayjs } from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { CountryType, countries } from "../global/countries";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { isEmail, getCurrentDate, password } from "../global/data";
import { InputAdornment, IconButton, Alert, AlertTitle } from "@mui/material";
import { apiHost, minPwLength } from "../global/definitions";
import AppFooter from "../hero/modules/views/AppFooter";

type StepsProps = {
  title: string;
  content: ReactNode;
  onClick?: () => void;
};

interface ContactInfoProps {
  fNameState: string;
  fNameChange: (event: ChangeEvent<HTMLInputElement>) => void;
  emailState: string;
  emailChange: (event: ChangeEvent<HTMLInputElement>) => void;
  countryState: CountryType | null;
  countryChange: Dispatch<SetStateAction<CountryType | null>>;
  phoneNoState: string;
  phoneNoChange: (event: ChangeEvent<HTMLInputElement>) => void;
  error: string;
  onEnterFunc: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

function ContactInfo({
  fNameState,
  fNameChange,
  emailState,
  emailChange,
  countryState,
  countryChange,
  phoneNoState,
  phoneNoChange,
  error,
  onEnterFunc,
}: ContactInfoProps) {
  return (
    <Grid
      container
      spacing={2}
      width="40vw"
      justifyContent="center"
      margin="auto"
    >
      <Grid item xs={12} sm={6}>
        <TextField
          label="Full Name"
          variant="filled"
          color="secondary"
          fullWidth
          value={fNameState}
          onChange={fNameChange}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label="Email"
          variant="filled"
          color="secondary"
          fullWidth
          value={emailState}
          onChange={emailChange}
        />
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          id="country-select"
          options={countries}
          value={countryState}
          onChange={(event, newValue) => countryChange(newValue)}
          autoHighlight
          getOptionLabel={(option) => option.label}
          renderOption={(props, option) => (
            <Box
              component="li"
              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
              {...props}
            >
              <img
                loading="lazy"
                width="20"
                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                alt=""
              />
              {option.label} ({option.code}) +{option.phone}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Choose a country"
              variant="filled"
              color="secondary"
              inputProps={{
                ...params.inputProps,
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Phone Number"
          variant="filled"
          color="secondary"
          fullWidth
          value={phoneNoState}
          onChange={phoneNoChange}
          onKeyDown={onEnterFunc}
        />
      </Grid>
      <Typography color="error" marginTop={3}>
        {error}
      </Typography>
    </Grid>
  );
}

interface AccountInfoProps {
  scState: string;
  scChange: (event: ChangeEvent<HTMLInputElement>) => void;
  bdState: Dayjs | null;
  bdSet: Dispatch<SetStateAction<Dayjs | null>>;
  passwordState: string;
  passwordChange: (event: ChangeEvent<HTMLInputElement>) => void;
  passwordConfirmState: string;
  passwordConfirmChange: (event: ChangeEvent<HTMLInputElement>) => void;
  error: string[];
  onEnterFunc: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

function AccountInfo({
  scState,
  scChange,
  bdState,
  bdSet,
  passwordState,
  passwordChange,
  passwordConfirmState,
  passwordConfirmChange,
  error,
  onEnterFunc,
}: AccountInfoProps) {
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Grid
        container
        spacing={2}
        width="40vw"
        justifyContent="center"
        margin="auto"
      >
        <Grid item xs={12}>
          <TextField
            label="School/Company"
            helperText="This will not be shown publicly and is optional"
            variant="filled"
            color="secondary"
            fullWidth
            value={scState}
            onChange={scChange}
          />
        </Grid>
        <Grid item xs={12}>
          <DemoContainer components={["DatePicker", "DatePicker"]}>
            <DatePicker
              label="Birthday"
              value={bdState}
              onChange={(newValue) => bdSet(newValue)}
              slotProps={{
                textField: {
                  variant: "filled",
                  color: "secondary",
                  fullWidth: true,
                },
              }}
            />
          </DemoContainer>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Password"
            variant="filled"
            color="secondary"
            type={showPassword ? "text" : "password"}
            value={passwordState}
            onChange={passwordChange}
            fullWidth
            required
            onPaste={handlePaste}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleTogglePasswordVisibility}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Confirm Password"
            variant="filled"
            color="secondary"
            type={showPassword ? "text" : "password"}
            value={passwordConfirmState}
            onChange={passwordConfirmChange}
            fullWidth
            required
            onKeyDown={onEnterFunc}
            onPaste={handlePaste}
          />
        </Grid>
        {error.length !== 0 && (
          <Grid item xs={12}>
            <Alert severity="error">
              <AlertTitle>Password Requirements</AlertTitle>
              Please fix the following error{error.length === 1 ? "" : "s"}:
              <ul>
                {error.map((item) => (
                  <li>{item}</li>
                ))}
              </ul>
            </Alert>
          </Grid>
        )}
      </Grid>
    </LocalizationProvider>
  );
}

interface MoreInfoProps {
  discoverState: string;
  discoverSet: (event: ChangeEvent<HTMLInputElement>) => void;
  intentState: string;
  intentSet: (event: ChangeEvent<HTMLInputElement>) => void;
}

function MoreInfo({
  discoverState,
  discoverSet,
  intentState,
  intentSet,
}: MoreInfoProps) {
  return (
    <div>
      <Grid
        container
        spacing={2}
        width="40vw"
        justifyContent="center"
        margin="auto"
      >
        <Grid item xs={12}>
          <TextField
            label="Where did you hear about us?"
            variant="filled"
            color="secondary"
            value={discoverState}
            onChange={discoverSet}
            fullWidth
            helperText="Where did you find out about us? Briefly describe ;)"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="How do you intend to use our app?"
            variant="filled"
            color="secondary"
            value={intentState}
            onChange={intentSet}
            fullWidth
            helperText="Just playing around? Trying out the app? Tell us! :P"
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default function SignUp() {
  const [activeStep, setActiveStep] = useState(0);
  const [fName, setFName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [selectedCountry, setSelectedCountry] = useState<CountryType | null>(
    null
  );
  const [contactInfoError, setContactInfoError] = useState("");
  const [sc, setSC] = useState("");
  const [bd, setBD] = React.useState<Dayjs | null>(dayjs(getCurrentDate()));
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [accountInfoError, setAccountInfoError] = useState<string[]>([]);
  const [discover, setDiscover] = useState("");
  const [intent, setIntent] = useState("");

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const checkContact: () => void = () => {
    if (fName !== "" && isEmail(email) && selectedCountry && phoneNo !== "") {
      setContactInfoError("");
      let phone = `+${selectedCountry.phone} ${phoneNo}`;
      // data to send to server
      console.log(fName, email, selectedCountry, phone);
      handleNext();
    } else {
      setContactInfoError("Not all fields are filled up correctly!");
    }
  };

  const checkAccount: () => void = () => {
    if (password === "" || passwordConfirm === "") {
      const requiredError = "Password is required";
      if (!accountInfoError.includes(requiredError)) {
        setAccountInfoError((prevErrors: string[]) => [
          ...prevErrors,
          requiredError,
        ]);
      }
    } else if (
      password.length < minPwLength ||
      passwordConfirm.length < minPwLength
    ) {
      const minLengthError = `Password must be at least ${minPwLength} characters long`;
      if (!accountInfoError.includes(minLengthError)) {
        setAccountInfoError((prevErrors: string[]) => [
          ...prevErrors,
          minLengthError,
        ]);
      }
    } else if (password !== passwordConfirm) {
      const matchError = "Passwords must match";
      if (!accountInfoError.includes(matchError)) {
        setAccountInfoError((prevErrors: string[]) => [
          ...prevErrors,
          matchError,
        ]);
      }
    } else {
      // No password errors, clear accountInfoError
      setAccountInfoError([]);
      handleNext();
    }
  };

  const checkMoreInfo: () => void = () => {
    console.log(discover, intent);
    handleNext();
  };

  const sendDataToServer = async () => {
    let phone = `+${selectedCountry?.phone} ${phoneNo}`;
    try {
      const response = await fetch(`${apiHost}users`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          fName: fName,
          email: email,
          phone: phone,
          sc: sc,
          password: password,
        }),
      });

      if (response.ok) {
        console.log("Data sent successfully to the server");
        window.location.href = "/";
      } else {
        console.error("Error sending data to the server");
        console.log(fName, email, phone, sc, password)
      }
    } catch (error) {
      console.error("Error sending data to the server", error);
    }
  };

  const handleFNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFName(event.target.value);
  };
  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };
  const handlePhoneNoChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPhoneNo(event.target.value);
  };
  const handleSCChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSC(event.target.value);
  };
  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };
  const handlePasswordConfirmChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setPasswordConfirm(event.target.value);
  };
  const handleDiscoverChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDiscover(event.target.value);
  };
  const handleIntentChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIntent(event.target.value);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      checkContact();
    }
  };

  const handleAccKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      checkAccount();
    }
  };

  const steps: StepsProps[] = [
    {
      title: "Your contact information",
      content: (
        <ContactInfo
          fNameState={fName}
          fNameChange={handleFNameChange}
          emailState={email}
          emailChange={handleEmailChange}
          countryState={selectedCountry}
          countryChange={setSelectedCountry}
          phoneNoState={phoneNo}
          phoneNoChange={handlePhoneNoChange}
          error={contactInfoError}
          onEnterFunc={handleKeyPress}
        />
      ),
      onClick: checkContact,
    },
    {
      title: "Your account information",
      content: (
        <AccountInfo
          scState={sc}
          scChange={handleSCChange}
          bdState={bd}
          bdSet={setBD}
          passwordState={password}
          passwordChange={handlePasswordChange}
          passwordConfirmState={passwordConfirm}
          passwordConfirmChange={handlePasswordConfirmChange}
          error={accountInfoError}
          onEnterFunc={handleAccKeyPress}
        />
      ),
      onClick: checkAccount,
    },
    {
      title: "Tell us more!",
      content: (
        <MoreInfo
          discoverState={discover}
          discoverSet={handleDiscoverChange}
          intentState={intent}
          intentSet={handleIntentChange}
        />
      ),
      onClick: checkMoreInfo,
    },
  ];

  return (
    <div>
      <NavBar />
      <Card
        variant="outlined"
        sx={{
          width: "50%",
          margin: "auto",
          marginTop: 5,
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            marginTop: 8,
            marginBottom: 6,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Stepper activeStep={activeStep}>
            {steps.map((label) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: {
                optional?: React.ReactNode;
              } = {};
              return (
                <Step key={label.title} {...stepProps}>
                  <StepLabel {...labelProps}>{label.title}</StepLabel>
                  <hr />
                </Step>
              );
            })}
          </Stepper>
          {activeStep === steps.length ? (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed - you&apos;re all set!
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Box sx={{ flex: "1 1 auto" }} />
                <Button
                  onClick={sendDataToServer}
                  variant="contained"
                  color="secondary"
                >
                  Complete Sign Up
                </Button>
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                {steps[activeStep].content}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  pt: 2,
                }}
              >
                {activeStep !== 0 && (
                  <Button
                    startIcon={<ArrowBack />}
                    size="large"
                    color="secondary"
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                    variant="contained"
                  >
                    Back
                  </Button>
                )}
                <Button
                  endIcon={
                    activeStep === steps.length - 1 ? (
                      <CheckCircle />
                    ) : (
                      <ArrowForward />
                    )
                  }
                  size="large"
                  variant="contained"
                  onClick={
                    steps[activeStep].onClick
                      ? steps[activeStep].onClick
                      : handleNext
                  }
                  color="secondary"
                >
                  {activeStep === steps.length - 1 ? "Finish" : "Next"}
                </Button>
              </Box>
            </React.Fragment>
          )}
        </Box>
      </Card>
      {/* <AppFooter /> */}
    </div>
  );
}
