import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeIcon from "@mui/icons-material/Home";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import NavBar from "./NavBar";
import { isMobileDevice, rdr } from "../global/data";
import CreatePost from "./CreatePost";

const drawerWidth = 240;

type MenuItemProps = {
  title: string;
  icon: React.ReactNode;
  onclick: () => void;
};

const MenuList: MenuItemProps[] = [
  { title: "Home", icon: <HomeIcon />, onclick: () => rdr("/") },
];

interface SideBarProps {
  content: React.ReactNode;
}

export default function SideBar({ content }: SideBarProps) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <NavBar />
      </AppBar>
      {!isMobileDevice() && (
        <Drawer
          variant="permanent"
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
        >
          <Toolbar />
          <Box sx={{ overflow: "auto" }}>
            <List>
              {MenuList.map((item) => (
                <ListItem key={item.title} disablePadding>
                  <ListItemButton onClick={item.onclick}>
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText primary={item.title} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
            <Divider />
            <CreatePost />
          </Box>
        </Drawer>
      )}
      <Box
        component="main"
        sx={{ flexGrow: 1, p: isMobileDevice() ? undefined : 3 }}
      >
        <Toolbar />
        {content}
      </Box>
    </Box>
  );
}
