import { useContext } from "react";
import Index from "../hero/Home";
import NavBar from "./NavBar";
import Feed from "../Feed";
import SideBar from "./SideBar";
import { AppContext } from "../../AppContext";

function NotLoggedIn() {
  return (
    <div>
      <NavBar />
      <Index />
    </div>
  );
}

function LoggedIn() {
  return (
    <div>
      <SideBar content={<Feed />} />
    </div>
  );
}

function Home() {
  const { globalState } = useContext(AppContext);

  if (globalState.isLoggedIn) {
    return <LoggedIn />;
  } else {
    return <NotLoggedIn />;
  }
}

export default Home;
